import React, { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

const auth = getAuth();
const provider = new GoogleAuthProvider();

const blogPosts = [
  {
    id: 1,
    title: 'Preparing for a Morgan Stanley Investment Banking Interview',
    publishedDate: 'Apr. 16, 2024',
    category: 'Recruiting • Investment Banking • Bulge Bracket',
    link: '/blog/morgan-stanley-process'
  },
  {
    id: 2,
    title: 'Navigating the Evercore M&A Investment Banking Summer Analyst Recruitment Process',
    publishedDate: 'Apr. 2, 2024',
    category: 'Recruiting • Investment Banking • Elite Boutique',
    link: '/blog/evercore-process'
  },
  {
    id: 3,
    title: 'Navigating the Qatalyst Summer Analyst Interview Process',
    publishedDate: 'Dec. 7, 2023',
    category: 'Recruiting • Investment Banking • Technology M&A',
    link: '/blog/qatalyst-process'
  },
  // Add more blog posts as needed
];



const Blog = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null); 
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        const userDisplayName = user.displayName;
        const userEmail = user.email;
        // navigate('/platform', { state: { userDisplayName, userEmail } });
      } else {
        // User is not signed in.
      }
    });
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const displayName = user.displayName;
        const email = user.email;
        console.log("User " + displayName + " signin success");

        navigate('/pricing', { state: { displayName, email } });
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScrollToBottom = () => {
    const bottomElement = document.getElementById('contact-bottom');

    if (bottomElement) {
      bottomElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToPricing = () => {
    navigate('/');
    
  };

  const handleContactClick = () => {
    handleToggleMenu();
    handleScrollToBottom();
  };

  const handlePricingClick = () => {
    handleToggleMenu();
    handleScrollToPricing();
  };
  
  return (
    <div >
      <Header
        handleToggleMenu={handleToggleMenu}
        handleScrollToPricing={handleScrollToPricing}
        handleScrollToBottom={handleScrollToBottom}
        handleSignup={handleSignup}
        handlePricingClick={handlePricingClick}
        handleContactClick={handleContactClick}
        menuOpen={menuOpen}
      />
      <div className="container mx-auto mt-8 h-screen max-w-4xl">
        <h1 className="text-3xl font-bold mb-4"><a className=''></a>Blog posts</h1>

        <div className="grid grid-cols-1 gap-6">
          {blogPosts.map((post) => (
            <Link to={post.link}>
              <div key={post.id} className="bg-gray-100 p-6 rounded-md shadow-md cursor-pointer hover:bg-gray-200">
                <h2 className="text-xl font-bold mb-2">{post.title}</h2>
                <p className=" mb-2">{post.publishedDate}</p>
                <p className="text-light-blue">{post.category}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Blog;
