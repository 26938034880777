// Schools.js

import React from 'react';
import { Link } from 'react-router-dom';

const Schools = () => {
  return (
    <div>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">Trusted by students from</h2>
        <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="https://1000logos.net/wp-content/uploads/2018/01/Wharton-logo.png" alt="Wharton" width="158" height="48"></img>
          <img class="col-span-2 max-h-14 w-full object-contain lg:col-span-1" src="https://1000logos.net/wp-content/uploads/2017/02/Harvard-Logo.png" alt="Harvard" width="158" height="48"></img>
          <img class="col-span-2 max-h-14 w-full object-contain lg:col-span-1" src="https://1000logos.net/wp-content/uploads/2022/08/NYU-Logo.png" alt="NYU" width="158" height="48"></img>
          <img class="col-span-2 max-h-10 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://fcba.berkeley.edu/wp-content/uploads/2023/07/berkeley-logo-2477383322.png" alt="Berkley" width="158" height="48"></img>
          <img class="col-span-2 col-start-2 max-h-16 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://1000logos.net/wp-content/uploads/2022/07/Georgetown-University-Font.png" alt="Georgetown" width="158" height="48"></img>
        </div>
      </div>
    </div>
  );
};

export default Schools;
