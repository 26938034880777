// Firms.js

import React from 'react';
import { Link } from 'react-router-dom';

const Firms = () => {
  return (
    <div>
      <h2 class="text-center text-lg font-semibold leading-8 text-gray-900 pb-6">Real interview questions from</h2>
        <div class="flex overflow-hidden space-x-16 group mx-4 md:mx-32">
          <div class="flex space-x-4 md:space-x-10 lg:space-x-10 animate-loop-scroll-fast md:animate-loop-scroll group-hover:paused">
            <img loading="lazy" src="https://www.blackstone.com/wp-content/uploads/sites/2/2020/07/Blackstone-PRESS-QUALITY-6312.png" class="w-32 object-contain" alt="Blackstone" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Goldman_Sachs.svg/1024px-Goldman_Sachs.svg.png" class="w-16 object-contain" alt="Goldman Sachs" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Kohlberg_Kravis_Roberts_%28logo%29.svg/2560px-Kohlberg_Kravis_Roberts_%28logo%29.svg.png" class="w-32 object-contain" alt="KKR" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Morgan_Stanley_Logo_1.svg/1200px-Morgan_Stanley_Logo_1.svg.png" class="w-32 object-contain" alt="Morgan Stanley" />
            <img loading="lazy" src="https://mma.prnewswire.com/media/320642/evercore_wealth_management_llclogo.jpg?p=twitter" class="w-32 object-contain" alt="Evercore" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/en/thumb/8/86/Ares_Management_logo.svg/2560px-Ares_Management_logo.svg.png" class="w-32 object-contain" alt="Ares" />
            <img loading="lazy" src="https://media2.vault.com/14343503/210909_jp-morgan_logo.jpg" class="w-16 object-contain" alt="JP Morgan" />
            {/* <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Jefferies_logo.svg/1280px-Jefferies_logo.svg.png" class="w-32 object-contain" alt="Jefferies" /> */}
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/9/93/Qatalyst_Partners.png" class="w-32 object-contain" alt="Qatalyst" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/0/05/PJT_Logo_Blue.png" class="w-16 object-contain" alt="PJT" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Vista_Equity_Partners_logo_2.svg/2560px-Vista_Equity_Partners_logo_2.svg.png" class="w-32 object-contain" alt="Vista" />
            <img loading="lazy" src="https://mms.businesswire.com/media/20230914412273/en/1889649/22/FP_LOGO_%281%29.jpg" class="w-28 object-contain" alt="FP" />
            
            <img loading="lazy" src="https://www.blackstone.com/wp-content/uploads/sites/2/2020/07/Blackstone-PRESS-QUALITY-6312.png" class="w-32 object-contain" alt="Blackstone" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Goldman_Sachs.svg/1024px-Goldman_Sachs.svg.png" class="w-16 object-contain" alt="Goldman Sachs" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Kohlberg_Kravis_Roberts_%28logo%29.svg/2560px-Kohlberg_Kravis_Roberts_%28logo%29.svg.png" class="w-32 object-contain" alt="KKR" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Morgan_Stanley_Logo_1.svg/1200px-Morgan_Stanley_Logo_1.svg.png" class="w-32 object-contain" alt="Morgan Stanley" />
            <img loading="lazy" src="https://mma.prnewswire.com/media/320642/evercore_wealth_management_llclogo.jpg?p=twitter" class="w-32 object-contain" alt="Evercore" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/en/thumb/8/86/Ares_Management_logo.svg/2560px-Ares_Management_logo.svg.png" class="w-32 object-contain" alt="Ares" />
            <img loading="lazy" src="https://media2.vault.com/14343503/210909_jp-morgan_logo.jpg" class="w-16 object-contain" alt="JP Morgan" />
            {/* <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Jefferies_logo.svg/1280px-Jefferies_logo.svg.png" class="w-32 object-contain" alt="Jefferies" /> */}
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/9/93/Qatalyst_Partners.png" class="w-32 object-contain" alt="Qatalyst" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/0/05/PJT_Logo_Blue.png" class="w-16 object-contain" alt="PJT" />
            <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Vista_Equity_Partners_logo_2.svg/2560px-Vista_Equity_Partners_logo_2.svg.png" class="w-32 object-contain" alt="Vista" />
            <img loading="lazy" src="https://mms.businesswire.com/media/20230914412273/en/1889649/22/FP_LOGO_%281%29.jpg" class="w-28 object-contain" alt="FP" />
          </div>
        </div>
    </div>
  );
};

export default Firms;
