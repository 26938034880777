import Flashcard from './Flashcard';
import React, { useEffect, useState, createRef } from "react";
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import './Cards.css'; 
import app from '../firebase';
import { getFirestore, collection, query, where, getDocs, doc, setDoc  } from "@firebase/firestore";
import Stripe from "stripe";
import { getAnalytics, logEvent } from "firebase/analytics";
import { format } from 'date-fns';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import {Card,CardContent,CardDescription,CardFooter,CardHeader,CardTitle,} from "../components/ui/card"
import {Form,FormControl,FormDescription,FormField,FormItem,FormLabel,FormMessage,} from "../components/ui/form"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { Dialog,DialogContent, DialogDescription,DialogHeader,DialogTitle,DialogTrigger,DialogFooter,DialogClose} from "../components/ui/dialog"
import { Label } from "../components/ui/label"
import { SelectValue, SelectTrigger, SelectItem, SelectContent, Select } from "../components/ui/select"
import { Checkbox, CheckboxGroup } from "../components/ui/checkbox"
import { Loader2 } from 'lucide-react';

const analytics = getAnalytics();
export const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY_PROD); 

function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }
  return randomId;
}

// Get flashcards from firebase
const cardsFromDb = [];
const categoriesFromDb = new Set();
const technicalCategoriesFromDb = new Set();
const db = getFirestore(app);
const q = query(collection(db, "cards"));
const querySnapshot = await getDocs(q);
var publicIdCount = 1;
const topic1 = "Practice";
const topic2 = "Invite";

querySnapshot.forEach((doc) => {
  cardsFromDb.push({
    id: doc.id,
    frontHTML: doc.get("question"),
    backHTML: doc.get("answer"),
    category: doc.get("category"),
    technical: doc.get("technical"),
    publicId: `${doc.get("category").substring(0, 4).toUpperCase().replace(/\s/g, '')}${publicIdCount}`,
  });
  categoriesFromDb.add(doc.get("category"));
  if (doc.get("technical")==="TRUE") technicalCategoriesFromDb.add(doc.get("category"));
  publicIdCount++;
});

function Cards() {
  const [user, setUser] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const [favoritesFromDb, setFavoritesFromDb] = useState([]);
  const [email, setEmail] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(topic1);
  const [savedChanges, setSavedChanges] = useState(false);
  const [gatherInfo, setGatherInfo] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if the user is authenticated
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        setIsLoading(true);
        await checkIfUserPaid(user);
        setIsLoading(false);
      } else {
        // User is not signed in.
        navigate('/');
      }
    });
    return () => unsubscribe(); 
  }, [navigate]);

  const checkIfUserPaid = async (user2) => {
    const email = user2.email;
    setEmail(user2.email);
    const displayName = user2.displayName;
    // console.log("checking for subscriptions with metadata: " + email);
    const subscription = await stripe.subscriptions.search({
      query: `status:'active' AND metadata['customerGoogleEmail']:'${email}'`,
    });
    // console.log(subscription)
    var navigateToCheckout = false;
    var groupSubscription = await checkForGroupUser(email);
    if (groupSubscription) {
      // console.log("Group subscription found!");
    } else {
      if (subscription.data.length == 0) {
        const calcelledSubscription = await stripe.subscriptions.search({
          query: `status:'canceled' AND metadata['customerGoogleEmail']:'${email}'`,
        });
        if (calcelledSubscription.data.length > 0) {
          // console.log("User with canceled subscription - period ended");
          navigateToCheckout = true;
        } else {
          // console.log("No subscription found"); 
          navigateToCheckout = true;
        }
      } else {
        if (subscription.data[0].cancel_at_period_end) {
          // console.log("User with canceled subscription - period not ended");
        } else {
          // console.log("Subscription found!");
        }
        checkForNewUser(email);
        //fully cancalled: status: active -> canceled
        //cancaled but period not over: cancel_at_period_end: false -> true
      }
    }
    
    
    if (navigateToCheckout) {
      navigate('/pricing', { state: { displayName, email } });
    } else {
      // Log for analytics 
      const now = new Date();
      const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss');
      getIPAddress(user2.email, formattedDateTime);
      setDoc(doc(db, "users", user2.email), {
        displayName: user2.displayName,
        uid: user2.uid,
        email: user2.email,
        lastLogin: formattedDateTime
      });

      // const qFavorites = query(collection(db, "favorites"), where("__name__", "==", user2.email));
      // const querySnapShotFavorites = await getDocs(qFavorites);
      // querySnapShotFavorites.forEach((doc) => {
      //   console.log(doc.id, " => ", doc.data());
      //   let newFavorites = doc.get("favorites");
      //   setFavoritesFromDb(newFavorites);
      //   // console.log("setting favorites");
      //   // console.log(newFavorites);
      // });
    }
  }

  const checkForGroupUser = async (email) => {
    const qUserInfo = query(collection(db, "groupUsers"), where("email", "==", email));
    const querySnapShotUserInfo = await getDocs(qUserInfo); 
    if (querySnapShotUserInfo.size > 0) {
      const doc = querySnapShotUserInfo.docs[0];
      const expirationTimestamp = doc.get("expiration");
      // console.log(expirationTimestamp.toDate());
      if (expirationTimestamp && expirationTimestamp.toDate() > new Date()) {
        return true;
      }
    }
    return false;
  }

  const checkForNewUser = async (email) => {
    const qUserInfo = query(collection(db, "usersInfo"), where("__name__", "==", email));
    const querySnapShotUserInfo = await getDocs(qUserInfo); 
    if (querySnapShotUserInfo.size == 0) {
      // new user
      const hasActiveSubscription = true; 
      navigate(`/create-account?email=${encodeURIComponent(email)}&activeSubscription=${hasActiveSubscription}`);
    }  
  }

  const getIPAddress = async (userEmail, formattedDateTime) => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const userIP = data.ip;
      await setDoc(doc(db, "logins", generateRandomId(8)), {
        page: 'platform',
        timestamp: formattedDateTime,
        userEmail: userEmail,
        ip: userIP
      });
    } catch (error) {
        await setDoc(doc(db, "logins", generateRandomId(8)), {
          page: 'platform',
          timestamp: formattedDateTime,
          userEmail: userEmail
        });
    }
}

  const categories = Array.from(categoriesFromDb);
  categories.unshift("All");
  const technicalCategories = Array.from(technicalCategoriesFromDb);
  technicalCategories.unshift("All");

  const handleSignout = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
        navigate('/');
      }).catch((error) => {
        console.log("signout error");
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  const feedbackFormLink = "https://airtable.com/appp3InCivAX3eOIh/shrpuMXGtKG6Wro3x";
  const openFeedbackForm = () => {
    window.open(feedbackFormLink, '_blank');
  };

  const handleMenuItemClick = (menuItem) => {
    setSavedChanges(false);
    setSelectedMenuItem(menuItem);
  }

  const referralFormSchema = z.object({
    venmoHandle: z.string().min(2, {
      message: "Venmo handle must be at least 2 characters.",
    }),
    emails: z.string().min(6, {
      message: "Email addresses must be at least 6 character.",
    }),
  })

  const referralForm = useForm({
    resolver: zodResolver(referralFormSchema),
    defaultValues: {
      venmoHandle: "",
      emails: "",
    },
  });

  async function onReferralSubmit(data) {
    console.log(data);
    const now = new Date();
    const unixTimestamp = Math.floor(now.getTime() / 1000);
    setDoc(doc(db, "referrals", generateRandomId(6)), {
      emails: data.emails,
      venmoHandle: data.venmoHandle,
      userEmail: user.email,
      time: unixTimestamp
    });
    setSavedChanges(true);
  }

  return (
    <div className="Cards" tabIndex={1} class="h-screen relative">
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      ) : ( 
        <>
      <Navbar/>
      <Sidebar
        handleMenuItemClick={handleMenuItemClick}
        topic1={topic1}
        topic2={topic2}
        selectedTopic={selectedMenuItem}
      />
      <div className='md:ml-68 pt-20'>
        {/* PLATFORM */}
        {(selectedMenuItem === topic1) && (
          <Flashcard
            initialCardIdx={0}
            allCategories={categories}
            cardsFromDb={cardsFromDb}
            technialCategories={technicalCategories}
            favoritesFromDb={favoritesFromDb}
            email={email}
          />
        )}
        {/* REFERRAL */}
        {(selectedMenuItem === topic2) && (
          <div className="pl-4">
            <p className='font-semibold text-lg text-black pb-0 mb-0'>Invite</p>
            <p className='font-medium text-base mb-4 text-slate-500 pt-0 mt-0'>Get paid to refer friends</p>
            <Card className="flex flex-col justify-center mr-4">
              <CardContent className="space-y-4 py-4 border-y">
              <div className="referral-program">
              <p className="text-black text-base">We're excited to introduce you to our referral program, designed to pay you for sharing Overlevered with your friends!</p>
              <Form {...referralForm}>
                  <form onSubmit={referralForm.handleSubmit(onReferralSubmit)} className="space-y-4">
                    {/* Venmo Field */}
                    <p className="text-black text-base"> Simply enter your Venmo username (@yourvenmo):</p>
                    <FormField
                      control={referralForm.control}
                      name="venmoHandle"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="flex space-x-2">
                              <Input id="venmo-output" placeholder="@JohnnyAppleseed" type="text" {...field} />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                      />
                    {/* Emails Field */}
                    <p className="text-black text-base">and the email addresses of the friends you'd like to refer:</p>
                    <FormField
                      control={referralForm.control}
                      name="emails"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="flex space-x-2">
                              <Input id="email-output" placeholder="friend1@gmail.com, friend2@gmail.com, friend3@gmail.com" type="text" {...field} />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <p className="text-black text-base">
                      Once your referred friends sign up for Overlevered, we'll Venmo you 20% of their subscription fee as a token of our appreciation!
                    </p>
                    <Button type="submit" className="font-medium mr-4 ml-0">Send referral email</Button>
                    {savedChanges && (<p className='text-green-600 text-base font-medium'>Your referral email is on its way!</p>)}
                  </form>
                </Form>
            </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      </>
      )}
    </div>
  );
}

export default Cards;
