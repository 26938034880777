// Header.js

import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ handleToggleMenu, handleScrollToPricing, handleScrollToBottom, handleSignup, handlePricingClick ,handleContactClick, menuOpen }) => {
  return (
    <header class="bg-white">
      <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a class="-m-1.5 p-1.5">
            {/* <span class="sr-only">Your Company</span> */}
            <Link to="/" className="text-med font-semibold leading-6 text-gray-900">Overlevered</Link>
            {/* <a class="text-med font-semibold leading-6 text-gray-900">Overlevered</a> */}
            {/* <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt=""></img> */}
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-white" onClick={handleToggleMenu}>
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <a class="text-sm font-semibold leading-6 text-gray-900 cursor-pointer" onClick={handleScrollToPricing}>Pricing</a>
          {/* <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Blog</a> */}
          <a className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer" onClick={handleScrollToBottom}>Contact</a>

        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <a class="text-sm font-semibold leading-6 text-gray-900 relative px-4 py-2 rounded-md transition duration-300 hover:bg-primary hover:text-white cursor-pointer" onClick={handleSignup}>
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>

    {/* Mobile menu, show/hide based on menu open state */}
    <div className="lg:hidden" role="dialog" aria-modal="true">
        {/* Background backdrop, show/hide based on slide-over state. */}
        {menuOpen && <div className="fixed inset-0 z-10" onClick={handleToggleMenu}></div>}
        {menuOpen && (
          <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                {/* <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" /> */}
              </a>
              <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={handleToggleMenu}>
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer" onClick={handlePricingClick}>
                    Pricing
                  </a>
                  <a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer" onClick={handleContactClick}>
                    Contact
                  </a>
                </div>
                <div className="py-6">
                  <a className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-black hover:bg-primary hover:text-white cursor-pointer" onClick={handleSignup}>
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      </header>
  );
};

export default Header;
