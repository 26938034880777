import React, { useState } from 'react';

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState([]);

  const toggleQuestion = (index) => {
    if (openQuestions.includes(index)) {
      setOpenQuestions(openQuestions.filter((item) => item !== index));
    } else {
      setOpenQuestions([...openQuestions, index]);
    }
  };

  const faqData = new Map([
    [
      1,
      {
        question: 'How is Overlevered different from other resources, like the M&I guide?',
        answer:
          "Other interview prep resources provide “common interview questions” while Overlevered lets you practice with questions that have actually been asked by the firms you’re recruiting for. <br/> <br/> The vast majority of our questions were asked in the last recruiting cycle, meaning there is a high chance you may be asked the same or similar questions this year.",
      },
    ],
    [
      2,
      {
        question: 'How many interview questions are on Overlevered?',
        answer: 'There are up to 30 interview questions per firm. For example, there are ~20 Evercore questions that were asked in the first round and superday of last year’s interview process.<br/><br/>Questions are removed after being on the platform for 2 years.',
      },
    ],
    [
      3,
      {
        question: 'From which firms does Overlevered source its questions?',
        answer: 'Questions on Overlevered are categorized by firm. These firms include, but are not limited to, Morgan Stanley (IBD), Goldman Sachs (IBD), Everocre (IBD), PJT (IBD), JP Morgan (IBD), Qatalyst (IBD), KKR (PE), Blackstone (PE), Fransisco Partners (PE), and Vista (PE).',
      },
    ],
  ]);
  

  return (
    <div id="accordion-collapse" data-accordion="collapse" className="mx-auto content-center center px-4 md:px-20 lg:max-w-5xl">
      <h2 className="mb-10 text-3xl tracking-tight font-bold text-gray-900 dark:text-white text-center">Frequently Asked Questions</h2>
      <div class="pr-6 md:pr-0">
      {[...faqData.entries()].map(([index, { question, answer }]) => (
        <div key={index}>
          <h2 id={`accordion-collapse-heading-${index}`}>
            <button
              type="button"
              onClick={() => toggleQuestion(index)}
              className={`flex items-center justify-between w-full p-5 font-medium text-black border-solid border-0 border-b-2
               border-grey rounded-none bg-white hover:bg-white gap-3 text-ls text-left`}
              data-accordion-target={`#accordion-collapse-body-${index}`}
              aria-expanded={openQuestions.includes(index)}
              aria-controls={`accordion-collapse-body-${index}`}
            >
              <span>{question}</span>
              <svg
                data-accordion-icon
                className={`w-3 h-3 transform ${openQuestions.includes(index) ? 'rotate-0' : 'rotate-180'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            id={`accordion-collapse-body-${index}`}
            className={`${openQuestions.includes(index) ? '' : 'hidden'}`}
            aria-labelledby={`accordion-collapse-heading-${index}`}
          >
            <div className="ml-2 py-5 border-solid border-b-2 border-grey">
              <p className="mb-2 text-gray-500 text-base font-base text-left px-5" dangerouslySetInnerHTML={{__html: answer}} />
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default FAQ;
