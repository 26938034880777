import Stripe from "stripe";
import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import Navbar from '../components/Navbar';
import Footer from './Footer';
import demoVideo from "./OverleveredDemo1.mov"
import demoVideoPoster from "./OverleveredDemoPoster.png"
import { cn } from "./lib/utils"
import { Loader2 } from 'lucide-react';
import app from '../firebase';
import { getFirestore, collection, query, where, getDocs, doc, setDoc  } from "@firebase/firestore";

export const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY_PROD);
const db = getFirestore(app);


export const LoadingSpinner = ({className}) => {
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn("animate-spin", className)}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
}

export default function App() {
  const [user, setUser] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const displayName = location.state ? location.state.displayName : "Guest"; 
  const [email, setEmail] = useState("guest@gmail.com");
  const [isSubscribe1Loading, setIsSubscribe1Loading] = React.useState(false);
  const [isSubscribe2Loading, setIsSubscribe2Loading] = React.useState(false);
  const [isSubscribe3Loading, setIsSubscribe3Loading] = React.useState(false);

  useEffect(() => {
    // Check if the user is authenticated
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
                
        checkIfUserPaid(user);
      } else {
        // User is not signed in.
        navigate('/');
      }
    });
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [navigate]);

  const checkIfUserPaid = async (user2) => {
    const userEmail = user2.email; //SETS TO EMAIIL CORRECTLY
    setEmail(user2.email); 

    checkForGroupUser(userEmail);

    // console.log("checking for subscriptions with metadata: " + userEmail);
    const subscription = await stripe.subscriptions.search({
      query: `status:'active' AND metadata['customerGoogleEmail']:'${userEmail}'`,
    });
    // console.log(subscription)
    if (subscription.data.length == 0) {
      const calcelledSubscription = await stripe.subscriptions.search({
        query: `status:'canceled' AND metadata['customerGoogleEmail']:'${userEmail}'`,
      });
      if (calcelledSubscription.data.length > 0) {
        // console.log("User with canceled subscription - period ended");
        checkForNewUser(userEmail);
      } else {
        // console.log("No subscription found"); 
        checkForNewUser(userEmail);
      }
    } else {
      if (subscription.data[0].cancel_at_period_end) {
        // console.log("User with canceled subscription - period not ended");
        navigate('/platform', { state: { displayName, userEmail } });
      } else {
        // console.log("Subscription found!");
        navigate('/platform', { state: { displayName, userEmail } });
      }
      //fully cancalled: status: active -> canceled
      //cancaled but period not over: cancel_at_period_end: false -> true
    }
  }

  const checkForGroupUser = async (email) => {
    const qUserInfo = query(collection(db, "groupUsers"), where("email", "==", email));
    const querySnapShotUserInfo = await getDocs(qUserInfo); 
    if (querySnapShotUserInfo.size > 0) {
      const doc = querySnapShotUserInfo.docs[0];
      const expirationTimestamp = doc.get("expiration");
      if (expirationTimestamp && expirationTimestamp.toDate() > new Date()) {
        // console.log("Group subscription found!");
        navigate('/platform', { state: { displayName, email } });
      } else {
        // console.log("group user not found");
      }
    } else {
      // console.log("group user not found");
    }
  }

  const checkForNewUser = async (email) => {
    const qUserInfo = query(collection(db, "usersInfo"), where("__name__", "==", email));
    const querySnapShotUserInfo = await getDocs(qUserInfo); 
    if (querySnapShotUserInfo.size == 0) {
      // new user
      navigate(`/create-account?email=${encodeURIComponent(email)}`);
    }  
  }


  const handleSignup = async (e, itemId) => {
    e.preventDefault();
    if (itemId == 1) {
      setIsSubscribe1Loading(true);
    } else if (itemId == 3) {
      setIsSubscribe2Loading(true);
    } else if (itemId == 6) {
      setIsSubscribe3Loading(true);
    }

    // console.log("checkout: ", email);
    // console.log(process.env.REACT_APP_STRIPE_SECRET_KEY_PROD);
    fetch('https://overlevered-server.web.app/create-checkout-session', { //http://localhost:3000/create-checkout-session
      // mode: 'no-cors',
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        items: [
          { id: itemId, googleEmail: email },
        ]
      })
    })
      .then(res => {
        if (res.ok) return res.json();
        return res.json().then(json => Promise.reject(json));
      })
      .then(({ url }) => {
        // console.log(url);
        window.location = url;
      })
      .catch(e => {
        console.error(e.error);
      });
  };

  const handleSignout = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
        navigate('/');
      }).catch((error) => {
        console.log("signout error");
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  
  return (
    <div>
      <Navbar/>
        <section class="mt-20 bg-white dark:bg-gray-900 pb-24">
          <div className="md:py-2 lg:py-6"/>
          <div class="px-4 mx-auto max-w-screen-xl">
            <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                <h2 class="mb-4 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">Start Interview Prepping Today</h2>
                <p class="mb-5 font-light italic text-gray-500 sm:text-lg dark:text-gray-400">"<span class="itlic"><span class="text-light-blue">Overlevered</span> helped me land offers at Morgan Stanely and PJT NY M&A! – Sophomore from UChicago"</span> </p>
            </div>
            <div class="space-y-5 sm:gap-1 lg:grid lg:grid-cols-3 lg:space-y-0 xl:gap-10">
                <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-grey border-2 shadow dark:border-grey dark:bg-black dark:text-white px-0 pt-0">
                    <div className="bg-grey text-grey text-center rounded-t-sm py-2 lg:w-80 xl:w-80 py-2 mb-4">
                    %
                    </div>
                    <h3 class="mb-4 text-xl font-semibold">1 Month</h3>
                    <div class="flex justify-center items-baseline my-8">
                        <span class="mr-2 text-5xl font-bold">$12</span>
                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                    </div>
                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-10">$12 total</p>
                    <div className={`relative z-10 flex justify-center items-center text-white bg-primary hover:bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-10 mx-10 ${isSubscribe1Loading ? 'py-2' : 'py-5'} text-center dark:text-white dark:focus:ring-primary-900`}>
                      {isSubscribe1Loading ? (
                        <Loader2 className={cn('h-6 w-6 text-white animate-spin')} />
                      ) : (
                        <a href="#" className="absolute inset-0 flex justify-center items-center text-white font-medium text-sm text-center dark:text-white" onClick={(e) => handleSignup(e, 1)}>Subscribe</a>
                      )}
                    </div>
                </div>
                <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-black border-4 shadow dark:border-black dark:bg-black dark:text-white px-0 pt-0">
                    <div className="bg-black text-white text-center rounded-t-sm py-2 lg:w-80 xl:w-80 py-2 mb-4">
                      Most Popular: Save 33%
                    </div>
                    <h3 class="mb-4 text-xl font-semibold">3 Months</h3>
                    <div class="flex justify-center items-baseline my-8">
                        <span class="mr-2 text-5xl font-bold">$8</span>
                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                    </div>
                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-10">$24 total</p>
                    <div className={`relative flex justify-center items-center text-white bg-primary hover:bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-10 mx-10 ${isSubscribe2Loading ? 'py-2' : 'py-5'} text-center dark:text-white dark:focus:ring-primary-900`}>
                      {isSubscribe2Loading ? (
                        <Loader2 className={cn('h-6 w-6 text-white animate-spin')} />
                      ) : (
                        <a href="#" className="absolute inset-0 flex justify-center items-center text-white font-medium text-sm text-center dark:text-white" onClick={(e) => handleSignup(e, 3)}>Subscribe</a>
                      )}
                    </div>
                </div>
                <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-grey border-2 shadow dark:border-grey dark:bg-black dark:text-white px-0 pt-0">
                    <div className="bg-grey text-black text-center rounded-t-sm py-2 lg:w-80 xl:w-80 py-2 mb-4">
                    Save 50%
                    </div>
                    <h3 class="mb-4 text-xl font-semibold">6 Months</h3>
                    <div class="flex justify-center items-baseline my-8">
                        <span class="mr-2 text-5xl font-bold">$6</span>
                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                    </div>
                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-10">$36 total</p>
                    <div className={`relative flex justify-center items-center text-white bg-primary hover:bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-10 mx-10 ${isSubscribe3Loading ? 'py-2' : 'py-5'} text-center dark:text-white dark:focus:ring-primary-900`}>
                      {isSubscribe3Loading ? (
                        <Loader2 className={cn('h-6 w-6 text-white animate-spin')} />
                      ) : (
                        <a href="#" className="absolute inset-0 flex justify-center items-center text-white font-medium text-sm text-center dark:text-white" onClick={(e) => handleSignup(e, 6)}>Subscribe</a>
                      )}
                    </div>
                </div>
            </div>
        </div>

        <div class="mx-auto max-w-screen-md text-center mt-8 pb-0 mb-0">
          <p class="text-sm"> By signing up, you are agreeing to our <a href="/terms-of-service" target="_blank" class="text-light-blue hover:underline">Terms of Service</a>.</p>
        </div>
      </section>


      
      
      <div class="bg-grey-3 py-8">
        <div class="text-center mb-4">
          <h2 class="text-xl font-semibold text-gray-800">Watch our demo</h2>
        </div>
        <div class="flex justify-center">
          <video playsInline autoPlay muted controls loop class="mx-auto" style={{ width: '90%', maxWidth: '600px', borderRadius: '8px' }} poster={demoVideoPoster} src={demoVideo}></video>
        </div>
      </div>

      
      

      <Footer/>
      
    </div>
  );
}
