import React, { useState, useEffect } from 'react';
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";

import { getFirestore, collection, query, where, getDocs, doc, setDoc  } from "@firebase/firestore";
import app from '../firebase';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }
  return randomId;
}

const db = getFirestore(app);


const Flashcard = ({ initialCardIdx, onKeyPress, cardsFromDb, allCategories, technialCategories, favoritesFromDb, email }) => {
  const [expanded, setExpanded] = useState(false);
  const [cardIdx, setCardIdx] = useState(initialCardIdx);
  const [filteredCards, setFilteredCards] = useState(cardsFromDb);
  const [selectedCategory, setSelectedCategory] = useState('All'); 
  const [unshuffledCards, setUnshuffledCards] = useState(cardsFromDb);
  const [isRandom, setIsRandom] = useState(false);
  const [isTechnical, setIsTechnical] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [categories, setCategories] = useState(allCategories);
  const [showTechnical, setShowTechnical] = useState(true);

  // Star
  const [isFavorited, setIsFavorited] = useState(false); // Tracks the favorite status
  const [hover, setHover] = useState(false); // Tracks the hover state
  // console.log("from flashcard");
  // console.log(favoritesFromDb)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePrevClick = () => {
    const newIndex = cardIdx - 1 < 0 ? filteredCards.length - 1 : cardIdx - 1;
    setCardIdx(newIndex);
    setExpanded(false); 
    const isCardFavorited = favoritesFromDb.includes(filteredCards[newIndex].id);
    setIsFavorited(isCardFavorited);
  };

  const handleNextClick = () => {
    const newIndex = cardIdx + 1 >= filteredCards.length ? 0 : cardIdx + 1;
    setCardIdx(newIndex);
    setExpanded(false);
    const isCardFavorited = favoritesFromDb.includes(filteredCards[newIndex].id);
    setIsFavorited(isCardFavorited);
  };

  const handleCategoryChange = (category, technical) => {
    setSelectedCategory(category);
    // Category
    if (category === 'All') {
      if (technical) {
        let filtered = cardsFromDb.filter((cardsFromDb) => cardsFromDb.technical === "TRUE");
        setUnshuffledCards(filtered);
        if (isRandom) shuffleArray(filtered);
        setFilteredCards(filtered);
      } else {
        setUnshuffledCards(cardsFromDb);
        if (isRandom) shuffleArray(cardsFromDb);
        setFilteredCards(cardsFromDb);
      }
    } else {
      if (technialCategories.includes(category)) {
        setShowTechnical(true);
      } else {
        setShowTechnical(false);
      }
      if (technical) {
        let filtered = cardsFromDb.filter((cardsFromDb) => cardsFromDb.category === category && cardsFromDb.technical === "TRUE");
        setUnshuffledCards(filtered);
        if (isRandom) shuffleArray(filtered);
        setFilteredCards(filtered);
      } else {
        let filtered = cardsFromDb.filter((cardsFromDb) => cardsFromDb.category === category);
        setUnshuffledCards(filtered);
        if (isRandom) shuffleArray(filtered);
        setFilteredCards(filtered);
      }
      
    }
  };

  const handleRandomToggle = () => {
    if (!isRandom) {
      console.log("randomize");
      console.log('filted: ', filteredCards)
      shuffleArray(filteredCards)
      setFilteredCards(filteredCards);
    } else {
      console.log("don't randomize");
      console.log('unshuffled: ', unshuffledCards)
      setFilteredCards(unshuffledCards);
    }
    setIsRandom(!isRandom);
  };

  const handleTechnicalToggle = () => {
    handleCategoryChange(selectedCategory, !isTechnical);
    if (!isTechnical) {
      setCategories(technialCategories);
    } else {
      setCategories(allCategories);
    }
    setIsTechnical(!isTechnical);
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (category) => {
    handleCategoryChange(category, isTechnical);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (typeof onKeyPress === 'function') {
        onKeyPress(event);
      }
      // console.log('Key pressed in Flashcard component:', event.keyCode);
      if (event.keyCode === 39) {
        handleNextClick();
      } else if (event.keyCode === 37) {
        handlePrevClick();
      } else if (event.keyCode === 32 || event.keyCode === 38 || event.keyCode === 40) {
        console.log('Key pressed flip', event.keyCode);
        handleExpandClick();
      }
    };
  
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onKeyPress, handleNextClick, handlePrevClick, handleExpandClick]); 

  useEffect(() => {
    const update = async () => {
      console.log(favoritesFromDb);
      await updateFavoritesDb();
    };
    if (favoritesFromDb.length > 0) { 
      update();
    }
  }, [favoritesFromDb]);

  const handleToggleFavorite = async (event) => {
    event.stopPropagation(); 
    console.log("clicked");
    const cardId = filteredCards[cardIdx].id; 
    console.log(cardId);

    if (!favoritesFromDb.includes(cardId)) {
      favoritesFromDb.push(cardId);
    } else {
      favoritesFromDb = favoritesFromDb.filter(item => item.cardId !== cardId);
    }

    setIsFavorited(!isFavorited); 
    updateFavoritesDb();
    
  };

  const updateFavoritesDb  = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log(email);
    await setDoc(doc(db, "favorites", email), {
      favorites: favoritesFromDb
    });
  }

  const iconColor = () => {
    if ((isFavorited && !hover) || (!isFavorited && hover)) {
      return 'black';
    } else {
      return '#e5e5e5';
    }
  };

  return (
    <div>
      {/* DROP DOWN MENU */}
      <div className="flex items-center justify-center mt-6 mb-6 relative"> 
        <div class="relative flex justify-center items-center">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            onClick={toggleDropdown}
            className="text-black bg-grey hover:bg-grey-3 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            type="button"
          >
            {selectedCategory === "All" ? "Select Firm" : selectedCategory}
            <svg
              className={`w-2.5 h-2.5 ms-3 transform pl-1 ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
            </svg>
          </button>
          {isDropdownOpen && (
            <div
              id="dropdown"
              className="z-10 absolute left-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 mt-6"
              style={{ top: "1rem" }} 
            >
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                {categories.map((category) => (
                  <li key={category}>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => handleItemClick(category)}
                    >
                      {category}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        

        {/* RANDOMIZE */}
        <div class="flex items-center">
          <input id="checked-checkbox" type="checkbox" checked={isRandom} onChange={handleRandomToggle}
            className="w-4 h-4 p-2 ml-2 mr-1 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary focus:ring-offset-gray-800 focus:ring-2"/>
          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900">Randomize</label>
        </div>
        {/* TECHNICAL */}
        {showTechnical && (<div class="flex items-center">
          <input id="checked-checkbox" type="checkbox" checked={isTechnical} onChange={handleTechnicalToggle}
            className="w-4 h-4 p-2 ml-2 mr-1 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary focus:ring-offset-gray-800 focus:ring-2"/>
          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900">Only Technical</label>
        </div>)}
      </div>
      
      {/* CARDS */}
      <div className='px-6 text-center pt-4'>
      <div className="flex justify-center"> 
        <div
          className={`relative w-full sm:w-full md:w-128 lg:w-128 xl:w-128 min-h-c mx-auto p-4 mx-4 cursor-pointer transition-all duration-800 rounded-md bg-white flex flex-col justify-center shadow-3xl`}
          onClick={handleExpandClick}
        >
          <div>
            {/* star */}
            {/* <div
              onClick={(e) => handleToggleFavorite(e)} // Pass the event to the handler
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{ cursor: 'pointer', color: iconColor() }}
              className='absolute top-0 left-0 mt-3 ml-4'
            >
              {isFavorited ? <FaStar size={16}/> : <FaRegStar size={16}/>}
            </div> */}

            {/* publicId */}
            <div className="absolute top-0 right-0 mt-2 mr-4 text-grey rounded-md">
              {filteredCards && filteredCards.length > 0 && filteredCards[cardIdx] && (
                filteredCards[cardIdx].publicId
              )}
            </div>
          </div>
          
          
          {/* front of card */}
          {filteredCards && cardIdx >= 0 && cardIdx < filteredCards.length && (
            <p className="mb-4 text-black text-left px-4">{filteredCards[cardIdx].frontHTML}</p>
          )}


          {/* back of card */}
          {expanded && (
            <div>
              {filteredCards && cardIdx >= 0 && cardIdx < filteredCards.length && (
                <p className="text-black text-left px-4 text-base/7">
                  {filteredCards[cardIdx].backHTML.split('|').map((line, index) => (
                    <React.Fragment key={index}>{line}<br /></React.Fragment>
                  ))}
                </p>
              )}
            </div>
            
          )}
        </div>
      </div>
        
        {/* PREV AND NEXT BUTTONS */}
        <div className="mt-4 pt-4">
          <button className="px-10 mr-6" onClick={handlePrevClick}>Prev</button>
          <button className="px-10" onClick={handleNextClick}>Next</button>
        </div>
        <div className="py-10"/>
      </div>
    </div>
  );
};

export default Flashcard;
