// Evercore.js

import React, { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

const auth = getAuth();
const provider = new GoogleAuthProvider();



const Evercore = () => {

  const GuideHeader = () => (
    <div className="my-8">
      <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Navigating the Evercore M&A Investment Banking Summer Analyst Recruitment Process</h1>
      <p>
        Welcome to our guide, crafted to provide you with insights into the Evercore interview process and equip you with the essential Evercore interview tips. 
        As you set your sights on securing a position in Evercore's M&A Summer Analyst program (Strategic Advisory), we recognize the significance of effective preparation.
        Renowned for its robust M&A and Restructuring teams, Evercore stands out with a powerful brand name in the finance industry.
        This reputation extends to the quality of exit opportunities, with successful candidates often finding themselves on paths leading to Megafund Private Equity and premier Hedge Funds.
        In this guide, we'll focus on the M&A team, unraveling the intricacies of Evercore's interview process and addressing common Evercore interview questions. 
      </p>
    </div>
  );

  const InterviewProcess = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Evercore Interview Process</h2>
      <p>
        Understanding Evercore’s interview process is a critical step towards securing a Summer Analyst position. Here's a breakdown of what you can expect:
      </p>
      <ol className="list-decimal ml-6 my-4">
        <li className="mb-2">
          <strong class="text-xl">First Round:</strong>
          <ul className="list-disc ml-6">
            <li><p>Format: Two 30-minute interviews via Zoom.</p></li>
            <li><p>Content: Interviews are 75% technical and 25% behavioral.</p></li>
          </ul>
        </li>
        <li className="mb-2">
          <strong class="text-xl">Superday:</strong>
          <ul className="list-disc ml-6">
            <li><p>Format: Four 30-minute interviews via Zoom or in-person.</p></li>
            <li><p>Content: Two purely technical interviews, one dedicated to behavioral questions, and one combining both technical and behavioral aspects.</p></li>
          </ul>
        </li>
      </ol>

      <h2 className="text-2xl font-bold mb-2">Evercore Recruiting Timeline</h2>
      <p>
        First round interviews typically take place in March or early April, with some interviews starting as early as the first week of March. Superdays are usually scheduled 2-3 weeks after the first round. Some applicants will be scheduled for a second round interview, which will be of similar format as the first.
      </p>
    </div>
  );
  
  const TechnicalQuestions = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Evercore Technical Interview Questions</h2>
      <p>
        Evercore interviews are known to be technical-heavy and considerably challenging. Be prepared for nuanced technical questions during both the first round and Superday. Notably, Evercore interview questions often feature a substantial focus on Accretion/Dilution questions. An example question that was asked during first round interviews for 2024 Summer Analysts is outlined below.
      </p>
      <div className="bg-grey-3 p-4 rounded-md my-4">
        <p className="text-base italic">
          <strong>Sample Question (asked in 2024):</strong> Company A trades at $2 per share, a P/E of 1x, and has 100 shares outstanding. Company B trades at $3 per share, a P/E of 1x, and has 100 shares outstanding. Company A buys Company B, offering $4 per share that is 50% cash (financed through Debt) and 50% stock. The Cost of Debt is 5% and matures over 5 years. There is $10 in financing fees and $10 in deal fees. Is this acquisition accretive or dilutive?
        </p>
      </div>
      <p>
        If you’re looking for more questions (and answers) that have been previously asked during Evercore M&A interviews, check out <a href="/" className="text-light-blue hover:underline">overlevered.com</a>, a valuable resource offering interview questions actually asked to applicants in the past. For a prestigious firm like Evercore, answering all of the technical interview questions correctly is not just a differentiator–it’s a prerequisite for landing the position.
      </p>
    </div>
  );
  
  const AdditionalInsights = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Additional Insights for Restructuring and Menlo Park Applicants</h2>
      <p>
        For those interested in restructuring (RX), it's important to note a slightly earlier timeline. Evercore's Menlo Park Tech team typically follows a timeline similar to the New York M&A team.
      </p>
    </div>
  );
  
  const Conclusion = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">In Conclusion</h2>
      <p>
        The Evercore M&A Investment Banking Summer Analyst program offers a gateway to a dynamic and rewarding career. By understanding the nuances of the recruitment process and preparing thoroughly, aspiring candidates can position themselves for success. One highly recommended resource is <a href="/" className="text-light-blue hover:underline">overlevered.com</a>, which provides real interview questions asked to past applicants. Stay focused, stay prepared, and embark on your journey towards joining one of the most esteemed names in the world of investment banking. Good luck!
      </p>
    </div>
  );
  

  const KeyWords = () => (
    <div className="">
      <p className="mb-4 text-secret text-sm">
        Navigating the Evercore interview process requires a comprehensive understanding of both behavioral and technical aspects. Aspiring candidates often seek Evercore interview tips to ensure they are well-prepared for the rigorous selection process. 
        The investment banking industry demands a high level of competence, making it imperative for individuals to focus on Evercore analyst interview preparation. 
        Common Evercore interview questions span a spectrum, covering behavioral inquiries that assess one's ability to work in a team and handle high-pressure situations, as well as technical questions that evaluate financial acumen.
      </p>
    </div>
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null); 
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        const userDisplayName = user.displayName;
        const userEmail = user.email;
        // navigate('/platform', { state: { userDisplayName, userEmail } });
      } else {
        // User is not signed in.
      }
    });
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const displayName = user.displayName;
        const email = user.email;
        console.log("User " + displayName + " signin success");

        navigate('/pricing', { state: { displayName, email } });
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScrollToBottom = () => {
    const bottomElement = document.getElementById('contact-bottom');

    if (bottomElement) {
      bottomElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToPricing = () => {
    navigate('/');
    
  };

  const handleContactClick = () => {
    handleToggleMenu();
    handleScrollToBottom();
  };

  const handlePricingClick = () => {
    handleToggleMenu();
    handleScrollToPricing();
  };
  
  
  return (
    <div>
      <Helmet>
        <title>Evercore Interview Questions</title>
        <meta name="description" content="Understanding Evercore’s interview process is a critical step towards securing a Summer Analyst position. Here's a breakdown of what you can expect:" />
      </Helmet>
      <Header
        handleToggleMenu={handleToggleMenu}
        handleScrollToPricing={handleScrollToPricing}
        handleScrollToBottom={handleScrollToBottom}
        handleSignup={handleSignup}
        handlePricingClick={handlePricingClick}
        handleContactClick={handleContactClick}
        menuOpen={menuOpen}
      />
      <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            {/* <header class="mb-4 lg:mb-6 not-format">
                <address class="flex items-center mb-6 not-italic">
                    <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                        <img class="mr-4 w-16 h-16 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-2.jpg" alt="Jese Leos"/>
                        <div>
                            <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Jese Leos</a>
                            <p class="text-base text-gray-500 dark:text-gray-400">Graphic Designer, educator & CEO Flowbite</p>
                            <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">Feb. 8, 2022</time></p>
                        </div>
                    </div>
                </address>
                <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Best practices for successful prototypes</h1>
            </header> */}
            
            <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">Apr. 2, 2024</time></p>
            <GuideHeader/>
            <InterviewProcess />
            <TechnicalQuestions />
            <AdditionalInsights />
            <Conclusion />
            <KeyWords />
            
          </article>
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default Evercore;
