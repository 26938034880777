import React, { useState, useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"

function Navbar() {
  const navigate = useNavigate();
  const [userPhotoURL, setUserPhotoURL] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if the user is authenticated
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        setUserPhotoURL(user.photoURL);
      } else {
        // User is not signed in.
        navigate('/');
      }
    });
    return () => unsubscribe(); 
  }, [navigate]);

  const handleSignout = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
        navigate('/');
      }).catch((error) => {
        console.log("signout error");
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleSettingsClick = () => {
    const userEmail = user.email
    navigate('/settings', {state: { userEmail }});
  }

  const feedbackFormLink = "https://airtable.com/appp3InCivAX3eOIh/shrpuMXGtKG6Wro3x";
  const openFeedbackForm = () => {
    window.open(feedbackFormLink, '_blank');
  };

  const manageSubscriptionLink = "https://billing.stripe.com/p/login/4gw02TcEb9bIeBy6oo";
  const openSubManagement = () => {
    window.open(manageSubscriptionLink, '_blank');
  };

  return (
    <nav class="fixed z-20 top-0 w-full bg-white border-slate-200 border-b dark:bg-gray-900">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 bg-white">
      <a class="flex items-center space-x-3 rtl:space-x-reverse bg-white py-3">
          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Overlevered</span>
      </a>
      {/* <div className="top-banner" class="text-center"> 
        <header class="text-med font-semibold text-center text-gray-800 pb-2 p-6">
          Overlevered
        </header> */}
      {/* </div> */}
      <div class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse bg-transparent hover:bg-transparen">
        <DropdownMenu class="flex items-center space-x-3 rtl:space-x-reverse">
          <DropdownMenuTrigger class="bg-transparent hover:bg-transparent p-0">
            <button type="button" class="bg-transparent p-0 hover:bg-transparent flex text-sm rounded-full md:me-0" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
              <img class="w-8 h-8 rounded-full" src={userPhotoURL} alt="user photo" />
            </button>
          </DropdownMenuTrigger>
          <div class="mr-4">
          <DropdownMenuContent>
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="cursor-pointer" onClick={openSubManagement}>Manage Subscription</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="cursor-pointer" onClick={openFeedbackForm}>Submit Feedback</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="cursor-pointer" onClick={handleSignout}>Log out</DropdownMenuItem>
          </DropdownMenuContent>
          </div>
          
        </DropdownMenu>
        
      </div>
      </div>
    </nav>
  );
}

export default Navbar;


