
import React, { useState, useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { HomeIcon, Share1Icon, RocketIcon } from '@radix-ui/react-icons'
import { Upload } from "lucide-react";




const Sidebar = ({ handleMenuItemClick, topic1, topic2, selectedTopic }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  }

  const handleMenuItemClickLocal = (topic) => {
    setSidebarOpen(!isSidebarOpen);
    handleMenuItemClick(topic);
  }

  return (
    <div id="sidebar">
      {/* <button 
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        class="inline-flex items-center p-2 mt-16 mb-0 ms-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        onClick={toggleSidebar}
      >
        <span class="sr-only">Open sidebar</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
      </button> */}
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-56 h-screen transition-transform mt-15 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`}
        aria-label="Sidebar"
      >
        <div class="h-full px-3 py-4 overflow-y-auto bg-white dark:bg-gray-800 border-slate-200 border-t border-r">
            <ul class="space-y-2 font-medium">
              <li>
                <a href="#" class={`flex items-center p-2 rounded-lg group 
                  ${selectedTopic === topic1 ? "bg-gray-700 text-white": "text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white"}`}
                  onClick={() => handleMenuItemClickLocal(topic1)}>
                  <RocketIcon className="h-4 w-4" />
                  <span class="flex-1 ms-3 whitespace-nowrap pl-2">{topic1}</span>
                </a>
              </li>
              
              <li>
                <a href="#" class={`flex items-center p-2 rounded-lg group 
                  ${selectedTopic === topic2 ? "bg-gray-700 text-white": "text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white"}`}
                  onClick={() => handleMenuItemClickLocal(topic2)}>
                  <Share1Icon className="h-4 w-4" />
                  <span class="flex-1 ms-3 whitespace-nowrap pl-2">{topic2}</span>
                </a>
              </li>
            </ul>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;






