import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {Form,FormControl,FormDescription,FormField,FormItem,FormLabel,FormMessage,} from "../components/ui/form"
import { getFirestore, collection, query, where, getDocs, doc, setDoc, addDoc, Timestamp } from "@firebase/firestore";
import app from '../firebase';
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"

import { useNavigate,useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { Loader2 } from 'lucide-react';


const db = getFirestore(app);

const KelloggSignUp = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  const formSchema = z.object({
    firstName: z.string().min(1, {
      message: "First name is required.",
    }),
    lastName: z.string().min(1, {
      message: "Last name is required.",
    }),
    email: z.string().email({
      message: "Invalid email address.",
    }).min(6, {
      message: "Email addresses must be at least 6 characters.",
    }),
    kelloggEmail: z.string().email({
      message: "Invalid email address.",
    }).min(6, {
      message: "Email addresses must be at least 6 characters.",
    }).refine(email => email.endsWith('@kellogg.northwestern.edu'), {
      message: "Email must end with '@kellogg.northwestern.edu'.",
    }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      kelloggEmail: "",
    },
  });

  useEffect(() => {
    // Check if the user is authenticated
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
      } else {
        // User is not signed in.
        navigate('/');
      }
    });
    return () => unsubscribe(); 
  }, [navigate]);

  async function onSubmit(data) {
    // console.log(data);
    const expiration = new Date("2025-01-22T05:00:00Z"); // January 22, 2025, 12:00:00 AM UTC-5
    var gmailEmail = user.email;
    if (gmailEmail == null) gmailEmail = data.email;
    setDoc(doc(db, "groupUsers", data.kelloggEmail), {
      ...data,
      email: gmailEmail,
      expiration: expiration,
    });
    alert("Sign up successful!");
    navigate('/platform');
  }

  return (
    <div>
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      ) : ( 
        <>
      <Navbar/>
      <div className="pt-20 text-center pb-4">
        <h2 className="text-xl font-bold mb-4">
          Sign Up for Select Northwestern Kellogg Students
        </h2>
        <p className="text-sm max-w-3xl mx-auto">
          Students eligible for this sign-up received an email from patrick@overlevered.com on October 24th with the subject 'Resource for Investment Banking Interview Prep – Access to Overlevered!'
        </p>
      </div>
      <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 max-w-lg mx-auto">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="firstName">First Name</Label>
                    <FormControl>
                      <Input id="firstName" placeholder="John" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="lastName">Last Name</Label>
                    <FormControl>
                      <Input id="lastName" placeholder="Doe" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="email">Personal Email</Label>
                  <FormControl>
                    <Input id="email" placeholder="john.doe@example.com" type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="kelloggEmail"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="kelloggEmail">Kellogg Email</Label>
                  <FormControl>
                    <Input id="kelloggEmail" placeholder="john.doe@kellogg.northwestern.edu" type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full ml-0" type="submit">Create Account</Button>
            <div className='py-6'/>
          </form>
        </Form>
        </>)}
    </div>

  );
};

export default KelloggSignUp;
