// Qatalyst.js

import React, { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

const auth = getAuth();
const provider = new GoogleAuthProvider();



const Qatalyst = () => {

  const GuideHeader = () => (
    <div className="my-8">
      <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Navigating the Qatalyst Summer Analyst Interview Process</h1>
      <p>
        Welcome to our guide on preparing for the Qatalyst Partners summer analyst interview process. Known for its focus on technology M&A, Qatalyst Partners is a highly sought-after firm in the investment banking industry. This guide will help you understand what to expect and how to prepare effectively.
      </p>
    </div>
  );
  
  const TechnicalPreparation = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Know Your Technicals Extremely Well</h2>
      <p>
        Obviously, mastering technical skills is crucial. Beyond standard guides, you should read Rosenbaum and Pearl at least twice, taking notes the second time. Additionally, explore various other sources, including YouTube channels like M&I and BIWS for solid content on M&A, LBO, etc. <a href="/" className="text-blue-600">Overlevered.com</a>  is also a great resource, offering ~20 nuanced interview questions that Qatalyst has asked past applicants. The best way to prepare for their interviews is to practice with these actual questions to avoid being blindsided.
      </p>
    </div>
  );
  
  const IndustryFamiliarization = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Familiarize Yourself with the Tech Industry</h2>
      <p>
        It is essential to have a deep understanding of the tech industry, focusing on main verticals such as software, internet, semiconductors, and fintech. Stay updated on industry trends, drivers, and outlooks. Familiarize yourself with major Tech M&A transactions, especially those over $1 billion in transaction value, and study Qatalyst's deals from the past few years.
      </p>
    </div>
  );
  
  const VerticalExpertise = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Know One Vertical Extremely Well</h2>
      <p>
        Be prepared to discuss one vertical within the tech industry in depth. You should be able to devote an entire call to that vertical or a specific company within it. This will demonstrate your expertise and dedication to understanding the sector.
      </p>
    </div>
  );
  
  const BehavioralPreparation = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Nail Your Behaviorals</h2>
      <p>
        Behavioral questions are critical, especially after the initial rounds that cover technical and industry-specific topics. Craft a compelling story about why you are interested in tech, M&A, and Qatalyst specifically. Your ability to articulate this narrative will be crucial in making a strong impression.
      </p>
    </div>
  );
  
  const StudyPlanAdvice = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Effective Study Plan</h2>
      <p>
        Start studying about a year before you anticipate your interview process starting. Do not cram; consistent study over time is key. Reach out to analysts, especially those who have lateraled, as they can be your best advocates in the process. Follow up diligently after they have sent your resume to the recruiter to secure an interview.
      </p>
    </div>
  );
  
  const ProcessAdvice = () => (
    <div className="my-8">
      <h2 className="text-2xl font-bold mb-2">Understanding the Qatalyst Interview Process</h2>
      <p>
        Qatalyst Partners moves slowly in their recruitment process. Start your networking early, especially if you are considering lateralling from another BB or EB, as their conversion to first rounds and proceeding rounds are slower. Even with competing offers from other top EBs and BBs, Qatalyst may not accelerate their process.
      </p>
      <p>
        Expect technical questions focused on SaaS. These may include standard banking questions applied to SaaS, such as deferred revenue for a SaaS company on a multi-year contract. Understanding SaaS terminology and technical terms is crucial. Resources like the "For Entrepreneurs" guide on SaaS can be very helpful. Additionally, be prepared to discuss recent deals or trends in the industry, highlighting how they reflect current market trends.
      </p>
      <p>
        Go beyond the guide answers in your preparation. Model out quick and dirty LBOs, merger models, and DCFs to gain a real understanding of how these pieces fit together.
      </p>
    </div>
  );
  
  const Keywords = () => (
    <div className="my-8">
      <h2 className="text-secret text-sm">Keywords to Keep in Mind</h2>
      <p className="text-secret text-sm">
        Incorporate these keywords into your preparation strategy: Qatalyst interview questions, Qatalyst summer analyst, Qatalyst Partners M&A, technology investment banking, technical interview questions, behavioral interview questions, SaaS financial modeling, deferred revenue SaaS, investment banking study plan, technology industry trends, and networking for investment banking.
      </p>
    </div>
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null); 
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        const userDisplayName = user.displayName;
        const userEmail = user.email;
        // navigate('/platform', { state: { userDisplayName, userEmail } });
      } else {
        // User is not signed in.
      }
    });
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const displayName = user.displayName;
        const email = user.email;
        console.log("User " + displayName + " signin success");

        navigate('/pricing', { state: { displayName, email } });
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScrollToBottom = () => {
    const bottomElement = document.getElementById('contact-bottom');

    if (bottomElement) {
      bottomElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToPricing = () => {
    navigate('/');
    
  };

  const handleContactClick = () => {
    handleToggleMenu();
    handleScrollToBottom();
  };

  const handlePricingClick = () => {
    handleToggleMenu();
    handleScrollToPricing();
  };
  
  
  return (
    <div>
      <Header
        handleToggleMenu={handleToggleMenu}
        handleScrollToPricing={handleScrollToPricing}
        handleScrollToBottom={handleScrollToBottom}
        handleSignup={handleSignup}
        handlePricingClick={handlePricingClick}
        handleContactClick={handleContactClick}
        menuOpen={menuOpen}
      />
      <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">Dec. 7, 2023</time></p>
            <GuideHeader />
            <TechnicalPreparation />
            <IndustryFamiliarization />
            <VerticalExpertise />
            <BehavioralPreparation />
            <StudyPlanAdvice />
            <ProcessAdvice />
            <Keywords />
            
          </article>
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default Qatalyst;
