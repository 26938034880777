// Footer.js

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer class="bg-white mx-auto rounded-lg shadow mx-4 bg-grey-3">
        <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400" id="contact-bottom">© Spectral Software Studio LLC 2024. Need to get in touch? Reach out at <a href="mailto:support@overlevered.com" class="text-light-blue hover:underline" target="_blank">support@overlevered.com</a>.
        </span>
        <ul class="flex flex-wrap items-center mt-3 gap-4 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li><Link to="/blog" className="hover:underline me-4 md:me-6" target="_blank">Blog</Link></li>
          <li><Link to="/terms-of-service" className="hover:underline me-4 md:me-6" target="_blank">Terms of Service</Link></li>
          {/* <li><a href="#" class="hover:underline me-4 md:me-6">Licensing</a></li> */}
          <li><a href="https://billing.stripe.com/p/login/4gw02TcEb9bIeBy6oo" target="_blank" class="hover:underline">Manage Subscription</a></li>
          <li><a href="mailto:support@overlevered.com" class="hover:underline">Contact</a></li>
          
        </ul>
        </div>
    </footer>
  );
};

export default Footer;
