// TermsOfService.js

import React, { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import app from '../firebase';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const auth = getAuth();
const provider = new GoogleAuthProvider();



const TermsOfService = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null); 
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        const userDisplayName = user.displayName;
        const userEmail = user.email;
        // navigate('/platform', { state: { userDisplayName, userEmail } });
      } else {
        // User is not signed in.
      }
    });
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const displayName = user.displayName;
        const email = user.email;
        console.log("User " + displayName + " signin success");

        navigate('/pricing', { state: { displayName, email } });
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScrollToBottom = () => {
    const bottomElement = document.getElementById('contact-bottom');

    if (bottomElement) {
      bottomElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToPricing = () => {
    const pricingElement = document.getElementById('pricing-div');

    if (pricingElement) {
      pricingElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleContactClick = () => {
    handleToggleMenu();
    handleScrollToBottom();
  };

  const handlePricingClick = () => {
    handleToggleMenu();
    handleScrollToPricing();
  };

  return (
    <div>
      <Header
        handleToggleMenu={handleToggleMenu}
        handleScrollToPricing={handleScrollToPricing}
        handleScrollToBottom={handleScrollToBottom}
        handleSignup={handleSignup}
        handlePricingClick={handlePricingClick}
        handleContactClick={handleContactClick}
        menuOpen={menuOpen}
      />

      <div className="container mx-auto my-8 p-8 bg-white">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Acceptance of Terms</h2>
        <p>
        This document sets forth the Terms of Service (the "Terms") for the product "Overlevered" (the "Service"), which is owned and operated by Spectral Software Studio LLC ("we", "us", or "our"). The Service is hosted on <a href="https://overlevered.com" className="text-light-blue hover:underline"> overlevered.com</a> (the "Website"). By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Description of Service</h2>
        <p>
        We provide services to help applicants prepare for job interviews. These services are subject to the terms and conditions outlined in this agreement.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">User Responsibilities</h2>
        <p>
          <strong>Account Registration:</strong> To use certain features of the service, you may be required to register for an account. You agree to provide accurate and complete information during the registration process.
        </p>
        <p>
          <strong>Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use or security breaches.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Content and Conduct</h2>
        <p>
          <strong>User Content:</strong> Users may submit content to our services. By doing so, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, and distribute the content.
        </p>
      </section>

      <section className="mb-6" >
        <h2 className="text-xl font-semibold mb-4" id="intellectual-property">Intellectual Property</h2>
        <p>
        We retain all rights, title, and interest in and to our services and its content. Users may not copy, modify, distribute, sell, or lease any part of our services or included software. Furthermore, users may not reverse engineer or attempt to extract the source code of that software without express written permission from us.
        </p>
        <p>
        Users acknowledge and agree that any unauthorized use of our services or included software may constitute a violation of intellectual property rights, leading to legal consequences.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Access Limitation</h2>
        <p>
Access to the Service is strictly limited to one individual per account. We reserve the right to terminate your account if it is accessed by multiple individuals. By using the Service, you agree to use your account solely for your personal use and benefit. You further agree not to permit any third party to use your account.        </p>
      </section>


      

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Termination</h2>
        <p>
        We reserve the right to terminate or suspend your account or subscription at any time for any reason, including violations of these terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Disclaimers and Limitation of Liability</h2>
        <p>
        Our services are provided "as is" and we make no warranties or representations regarding the accuracy or completeness of the content. We shall not be liable for any direct, indirect, incidental, special, or consequential damages.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Changes to Terms</h2>
        <p>
        We reserve the right to modify or revise these terms at any time. Users will be notified of changes, and continued use of the services after changes constitute acceptance of the modified terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Subscription Cancellation and Access</h2>
        <p>
        To cancel your subscription, click “Manage Subscription" in the website footer below. Please email{' '}
          <a href="mailto:support@overlevered.com" className="text-light-blue hover:underline">
            support@overlevered.com
          </a> if you have any issues canceling your subscription.
        </p>

        <p>
        Upon subscribing to our service, users are granted access to the Service for the period specified at the time of purchase (for example, from January 1 to February 1). If a user decides to cancel their subscription, access to the Service is terminated immediately upon cancellation.
        </p>

        <p>
        For clarity, if a user purchases a subscription for the period from January 1 to February 1 and then cancels the subscription on January 10, the user's access to the Service will cease on January 10. Access to the Service will not continue until the originally scheduled end date of February 1. It is important for users to understand that subscription fees are not prorated upon cancellation, and no refunds will be provided for the remaining period of access originally purchased. 
        </p>
        
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
        <p>
          If you have any questions or concerns about these terms, please contact us at{' '}
          <a href="mailto:support@overlevered.com" className="text-light-blue hover:underline">
            support@overlevered.com
          </a>.
        </p>
      </section>
    </div>

      <Footer/>
    </div>

    
  );
};

export default TermsOfService;
