import Stripe from "stripe";
import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import app from '../firebase';
import { getFirestore, doc, setDoc  } from "@firebase/firestore";
import { format } from 'date-fns';
export const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY_PROD);

const db = getFirestore(app);

// Generate Random Id
function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }
  return randomId;
}

export default function App() {
  const [user, setUser] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state ? location.state.email : "guest@gmail.com"; 
  const displayName = location.state ? location.state.displayName : "Guest"; 
  const [userEmail, setUserEmail] = useState("guest@gmail.com");
  if (location.state) {
    setUserEmail(location.state.email)
  }

  useEffect(() => {
    // Check if the user is authenticated
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
        //console.log(email);
        checkIfUserPaid(user);
      } else {
        // User is not signed in.
        navigate('/');
      }
    });
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [navigate]);

  const checkIfUserPaid = async (user2) => {
    setUserEmail(user2.email);
    const userDisplayName = user2.displayName;
    console.log("checking for subscriptions with metadata: " + userEmail);
    const subscription = await stripe.subscriptions.search({
      query: `status:'active' AND metadata['customerGoogleEmail']:'${userEmail}'`,
    });
    console.log(subscription)
    var activeSubscription = false;
    if (subscription.data.length == 0) {
      const calcelledSubscription = await stripe.subscriptions.search({
        query: `status:'canceled' AND metadata['customerGoogleEmail']:'${userEmail}'`,
      });
      if (calcelledSubscription.data.length > 0) {
        activeSubscription = false;
        console.log("User with canceled subscription - period ended");
      } else {
        activeSubscription = false;
        console.log("No subscription found"); 
      }
    } else {
      if (subscription.data[0].cancel_at_period_end) {
        activeSubscription = false;
        console.log("User with canceled subscription - period not ended");
        navigate('/platform', { state: { displayName, email } });
      } else {
        activeSubscription = true;
        console.log("Subscription found!");
        navigate('/platform', { state: { displayName, email } });
      }
      //fully cancalled: status: active -> canceled
      //cancaled but period not over: cancel_at_period_end: false -> true
    }
  }

  const [popupVisible, setPopupVisible] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [attemptCount, setAttemptCount] = useState(1);

  const checkIfUserPaidButtonFunction = async () => {
    setButtonVisible(false);
    console.log("checking for subscriptions with metadata: " + userEmail);
    const subscription = await stripe.subscriptions.search({
      query: `status:'active' AND metadata['customerGoogleEmail']:'${userEmail}'`,
    });
    console.log(subscription)
    var activeSubscription = false;
    if (subscription.data.length == 0) {
      const calcelledSubscription = await stripe.subscriptions.search({
        query: `status:'canceled' AND metadata['customerGoogleEmail']:'${userEmail}'`,
      });
      if (calcelledSubscription.data.length > 0) {
        activeSubscription = false;
        console.log("User with canceled subscription - period ended");
      } else {
        activeSubscription = false;
        console.log("No subscription found"); 
      }
    } else {
      if (subscription.data[0].cancel_at_period_end) {
        activeSubscription = false;
        console.log("User with canceled subscription - period not ended");
      } else {
        activeSubscription = true;
        console.log("Subscription found!");
        // navigate('/platform', { state: { displayName, email } });
      }
      //fully cancalled: status: active -> canceled
      //cancaled but period not over: cancel_at_period_end: false -> true
    }
    if (!activeSubscription) {
      setPopupVisible(true);      
    }
  }

  const handleTryAgain = () => {
    setAttemptCount((prevCount) => prevCount + 1);
    checkIfUserPaidButtonFunction();
  };

    const handleClosePopup = () => {
      // Set the state to hide the popup
      setPopupVisible(false);
    };

  const paymentErrorFormLink = "https://airtable.com/appp3InCivAX3eOIh/shrRFsS1Slp3EBZOJ";
  const openPaymentErrorForm = () => {
    window.open(paymentErrorFormLink, '_blank');
  };

  const [formData, setFormData] = useState({
    source: '',
  });
  const [submitted, setSubmitted] = useState(false);

  // Event handler for form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // Log response to Database
    const now = new Date();
    const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss');
    setDoc(doc(db, "findUs", generateRandomId(8)), {
      source: formData.source,
      timestamp: formattedDateTime,
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        {buttonVisible && (
          <button onClick={checkIfUserPaidButtonFunction} className="checkout-button">
          Redirect to Platform
          </button>
        )}
        
        {popupVisible && (
          <div className="popup">
            <div className="popup-content">
              <p class="pb-4 text-xl text-black text-center">Subscription not found ({attemptCount}). Please give our backend 30 seconds to confirm your payment from Stripe.</p>
              {!submitted && (
              <div>
              <p class="pb-4 text-xl text-black text-center">While you wait, please let us know how you heard about Overlevered!</p>
              <form onSubmit={handleSubmit} className="flex flex-col items-center">
                <label className="mx-auto text-center">
                  <textarea
                    name="source"
                    value={formData.source}
                    onChange={handleInputChange}
                    className="bg-grey-3 p-2 py-4 rounded-md"
                    cols="40"
                    rows="3" 
                  />
                </label>
                <br />

                <button type="submit" class="mx-auto block mb-10" >Submit</button>
              </form>
              </div>
              )}
              <button class="mx-auto block" onClick={handleTryAgain}>Try Again</button>
            </div>
          </div>
        )}
      </div>

      <div className="feedback-button" onClick={openPaymentErrorForm}>
        Payment Error? Please let us know so we can fix it
      </div>
    </div>
  );
}