import { initializeApp } from "firebase/app";
import { getAnalytics } from "@firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDG7o5aMOsRCkwVC-OQzALT3xO0PXoE6V8",
  authDomain: "overlevered.com", 
  projectId: "flashcards-b2cb7",
  storageBucket: "flashcards-b2cb7.appspot.com",
  messagingSenderId: "482693884055",
  appId: "1:482693884055:web:fa33a241ac2ae1606a21d2",
  measurementId: "G-HCZW51WDD1"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;